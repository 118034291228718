<template>
  <v-main>
    <v-container>
      <v-layout align-center justify-center> </v-layout>
      <h3 class="mb-5 mt-5">Lesson List</h3>
      <v-text-field
        placeholder="Search Lesson"
        v-model="searchQuery"
        prepend-icon="mdi-magnify"
        clearable
      >
        Search Lesson
      </v-text-field>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div v-if="loading">Loading...</div>
      <v-btn to="/createlesson"> Create Lesson </v-btn>
      <v-data-table
        :headers="headers"
        :items="filterLesson"
        :items-per-page="20"
        item-key="`${id}-${languageId}`"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Lesson List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.lectureId="{ item }">
          {{
            lectures.find((x) => x.id == item.lectureId).lectureTitle +
            " - " +
            lectures.find((x) => x.id == item.lectureId).description
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item.id, item.languageId)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon> -->
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  FETCH_LESSONLIST,
  GET_REF_TRAINING_DATA,
  GET_LESSON_DETAIL,
} from "../store/actions.type";
export default {
  name: "LessonList",
  data() {
    return {
      lectures: [],
      lessonList: [],
      searchQuery: "",
      info: null,
      loading: true,
      errored: false,
      headers: [
        { 
          text: "Language",
          align: "start",
          sortable: true,
          value: "languageName",
        },
        { 
          text: "LectureID",
          align: "start",
          sortable: true,
          value: "lectureId",
        },
        { 
          text: "Title",
          align: "start",
          sortable: true,
          value: "title",
        },
        { text: "Sort", value: "indexSort" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    filterLesson() {
      if (this.searchQuery) {
        return this.lessonList.filter((item) => {
          return item.title
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });

      } else {
        return this.lessonList;
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(GET_REF_TRAINING_DATA)
      .then((data) => {
        this.loading = false;
        this.lectures = data.data.lectures;
        localStorage.setItem("lectures", JSON.stringify(this.lectures));
        this.$store
          .dispatch(FETCH_LESSONLIST)
          .then((data) => {
            this.loading = false;
            this.lessonList = data;
            console.log(this.lessonList);
          })
          .catch((response) => {
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  created() {},
  methods: {
    editItem(id, languageId) {
      var payload = {
        lessonId: id,
        languageId: languageId
      }
      this.$store
        .dispatch(GET_LESSON_DETAIL, payload)
        .then((data) => {
          this.loading = false;
          var lesson = data;
          console.log(lesson);
          localStorage.setItem("lesson", JSON.stringify(lesson));
          this.$router.push({
            name: "EditLesson",
            params: {
              lessonId: id
            },
          });
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
